import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import "./Button.scss";

export default function Button({
  border,
  filled,
  large,
  white,
  children,
  anchor,
  to,
}) {
  if (anchor) {
    return (
      <a href={"/#" + anchor}>
        <button
          className={classNames("Button", { border, filled, large, white })}
        >
          {children}
        </button>
      </a>
    );
  }
  return (
    <Link
      to={to}
      className={classNames("Button", { border, filled, large, white })}
    >
      {children}
    </Link>
  );
}
