import React from "react";
import Nav from "../Nav/Nav";
import "./Layout.scss";

export default function Layout({ children, className, activeElement, sub }) {
  return (
    <div className={"Layout " + className}>
      <Nav activeElement={activeElement} sub={sub}></Nav>
      <div className="inner">{children}</div>
    </div>
  );
}
