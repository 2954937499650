import classNames from "classnames";
import React from "react";
import IntersectionNotifier from "../IntersectionNotifier/IntersectionNotifier";
import "./Hero.scss";

export default function Hero({
  bg,
  mobileBg,
  hasScrolledIntoView = () => {},
  children,
  anchor,
}) {
  const style = {};
  if (bg) {
    style.backgroundImage = `url("/${bg}.jpg")`;
  }
  const mobileStyle = {};
  if (mobileBg) {
    mobileStyle.backgroundImage = `url("/${mobileBg}.jpg")`;
  }
  return (
    <>
      <a id={anchor} href="/#" className="invisibleAnchor">
        #
      </a>
      <div style={style} className={classNames("Hero", "desktopHero", { bg })}>
        {children}

        <div className="bottomRef">
          <IntersectionNotifier
            hasScrolledIntoView={hasScrolledIntoView}
          ></IntersectionNotifier>
        </div>
      </div>
      <div
        style={mobileStyle}
        className={classNames("Hero", "mobileHero", { bg: mobileBg })}
      >
        {children}
      </div>
    </>
  );
}
