import "./App.scss";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./screens/Home/Home";
import Contact from "./screens/Contact/Contact";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home></Home>,
  },
  {
    path: "/contact",
    element: <Contact></Contact>,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
