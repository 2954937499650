import React, { useState } from "react";
import Button from "../Button/Button";
import "./Nav.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaTimes } from "react-icons/fa";
import Spacer from "../Spacer/Spacer";
import { Link } from "react-router-dom";
export default function Nav({ activeElement, sub }) {
  const [navOpen, setNavOpen] = useState(false);
  return (
    <>
      <nav className="Nav desktopNav">
        <Link to="/" className="logo">
          AVEMUS.APP
        </Link>
        {!sub && (
          <>
            <div className="centerNav">
              <Button border={activeElement === 0} anchor="entdecken">
                Entdecken
              </Button>
              <Button border={activeElement === 1} anchor="verstehen">
                Verstehen
              </Button>
              <Button border={activeElement === 2} anchor="loslegen">
                Loslegen
              </Button>
            </div>
            <div className="rightNav">
              <Button to="/contact" filled>
                Demo anfordern
              </Button>
              <a
                target={"_blank"}
                rel="noreferrer"
                href="https://admin.avemus.app/"
              >
                <div className="Button border">Admin Login</div>
              </a>
            </div>
          </>
        )}
      </nav>
      <nav className="Nav mobileNav">
        <Link to="/" className="logo">
          AVEMUS.APP
        </Link>
        <div className="flex1"></div>
        {!sub && (
          <>
            <div
              className="mobileNavButton"
              onClick={() => {
                setNavOpen(!navOpen);
              }}
            >
              <GiHamburgerMenu />
            </div>
          </>
        )}
      </nav>
      {navOpen && (
        <div className="mobileNavOverlay">
          <nav className="Nav mobileNav">
            <div className="logo">AVEMUS.APP</div>
            <div className="flex1"></div>
            <div
              className="mobileNavButton"
              onClick={() => {
                setNavOpen(!navOpen);
              }}
            >
              <FaTimes />
            </div>
          </nav>
          <a
            onClick={() => {
              setNavOpen(false);
            }}
            href={"#entdecken"}
            className="mobileNavItem"
          >
            Entdecken
          </a>
          <a
            onClick={() => {
              setNavOpen(false);
            }}
            href={"#verstehen"}
            className="mobileNavItem"
          >
            Verstehen
          </a>
          <a
            onClick={() => {
              setNavOpen(false);
            }}
            href={"#loslegen"}
            className="mobileNavItem"
          >
            Loslegen
          </a>
          <Spacer h={24}></Spacer>
          <div className="buttons">
            <Spacer h={24}></Spacer>
            <Button filled large to="/contact">
              Demo anfordern
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
