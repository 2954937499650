import React from "react";
import Button from "../Button/Button";
import IntersectionNotifier from "../IntersectionNotifier/IntersectionNotifier";
import Spacer from "../Spacer/Spacer";
import "./TrippleArea.scss";

export default function TrippleArea({ hasScrolledIntoView }) {
  return (
    <>
      <a id={"verstehen"} href="/#" className="invisibleAnchor">
        #
      </a>
      <IntersectionNotifier
        hasScrolledIntoView={hasScrolledIntoView}
      ></IntersectionNotifier>
      <div className="desktopHeader">
        <Spacer h={80}></Spacer>
        <div className="title">
          <span className="orange">Interkative</span> AUSSTELLUNGEN
        </div>
        <div className="sub">In drei einfachen Schritten</div>
      </div>
      <div className="TrippleArea">
        <Section
          icon={"1.png"}
          num="1"
          title={<>Sammlung{"\n"}Anlegen</>}
          text="Legen Sie Ihre Exponate mit wenigen Klicks an. Ergänzen Sie anschließend alle Informationen und Medien wie z.B. Audio, Bilder, Videos, Grafiken, 3D Modelle und mehr!"
        ></Section>
        <Section
          icon={"2.png"}
          num="2"
          title={<>Exponate{"\n"}verorten</>}
          text="Mit Ihrem Grundriss als Übersichtskarte können Sie Exponate platzieren und an der richtigen Stelle im Museum verorten."
        ></Section>
        <Section
          icon={"3.png"}
          num="3"
          title={<>Führungen{"\n"}zuordnen</>}
          text="Zum Schluss konzipieren Sie aus den vorhandenen Exponaten individuelle Führungen oder Schnitzeljagden."
        ></Section>
      </div>
      <div className="desktopButton">
        <Button filled to="/contact">
          Demo anfordern
        </Button>
        <Spacer h={80}></Spacer>
      </div>
    </>
  );
}

function Section({ icon, num, title, text }) {
  return (
    <div className="Section">
      <Spacer h={40}></Spacer>
      <div className="title">
        <span className="orange">Interkative</span> AUSSTELLUNGEN
      </div>

      <div className="sub">In drei einfachen Schritten</div>
      <Spacer h={40}></Spacer>
      <div className="icon">
        <img alt="icon" src={"/icons/" + icon}></img>
      </div>
      <Spacer h={40}></Spacer>
      <div className="step">
        <div className="num">{num}.</div>
        <div className="name">{title}</div>
      </div>
      <Spacer h={24}></Spacer>
      <div className="text">{text}</div>
      <Spacer h={40}></Spacer>
      <div className="button">
        <Button to="/contact" filled>
          Demo anfordern
        </Button>
      </div>
    </div>
  );
}
