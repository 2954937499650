import React, { useCallback, useState } from "react";
import { FaCheck, FaRegCheckCircle } from "react-icons/fa";
import Loading from "react-loading";
import Button from "../../atoms/Button/Button";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";
import "./Contact.scss";

export default function Contact({ defaultMessage = "" }) {
  let [name, setName] = useState("");
  let [company, setCompany] = useState("");
  let [mail, setMail] = useState("");
  let [phone, setPhone] = useState("");
  let [option, setOption] = useState("demo");
  let [message, setMessage] = useState(defaultMessage);
  let [status, setStatus] = useState("start");

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      console.log("send form");
      let formData = new FormData();
      formData.append("name", name);
      formData.append("company", company);
      formData.append("mail", mail);
      formData.append("phone", phone);
      formData.append("message", message);
      formData.append("option", option);
      formData.append("form-name", "Contact");
      setStatus("loading");
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {
          console.log("Form successfully submitted");
          setStatus("done");
          //rest all states
          setName("");
          setCompany("");
          setMail("");
          setPhone("");
          setMessage("");
        })
        .catch((error) => {
          setStatus("error");
          console.log(error);
        });
    },
    [name, mail, message, company, option, phone]
  );
  return (
    <Layout className="Contact" sub>
      <Spacer h={80}></Spacer>
      <div className="contactInner">
        <div className="contactTitle">Kontaktieren Sie uns</div>
        <div className="contactSub">
          oder senden Sie uns eine E-Mail an{" "}
          <a href="mailto:hallo@avemus.de">hallo@avemus.de</a>
        </div>
        <div className="contactContent">
          <label className="label">
            Ansprechpartner
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="Ihr Name"
              className="contactInput"
            />
          </label>
          <label className="label">
            Name der Einrichtung
            <input
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              type="text"
              placeholder="Name Ihrer Einrichtung"
              className="contactInput"
            />
          </label>
          <label className="label">
            E-Mail
            <input
              value={mail}
              onChange={(e) => setMail(e.target.value)}
              type="text"
              placeholder="petra@museum.de"
              className="contactInput"
            />
          </label>
          <label className="label">
            Telefon
            <input
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              type="text"
              placeholder="0123 456789"
              className="contactInput"
            />
          </label>
          <label className="label">
            Ihre Nachricht (Optional)
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              type="text"
              placeholder="Haben Sie uns noch etwas zu sagen?"
              className="contactInput"
            />
          </label>
          <label className="label">
            Wie können wir helfen?
            <div className="optionSelector">
              <div
                onClick={() => {
                  setOption("demo");
                }}
                className={"option " + (option === "demo" ? "active " : " ")}
              >
                <FaRegCheckCircle size={15}></FaRegCheckCircle>
                <Spacer w={8}></Spacer>
                Demo buchen
              </div>
              <div
                onClick={() => {
                  setOption("telefon");
                }}
                className={"option " + (option === "telefon" ? "active " : " ")}
              >
                <FaRegCheckCircle size={15}></FaRegCheckCircle>
                <Spacer w={8}></Spacer>
                Telefonisches Beratungsgespräch
              </div>
              <div
                onClick={() => {
                  setOption("infos");
                }}
                className={"option " + (option === "infos" ? "active " : " ")}
              >
                <FaRegCheckCircle size={15}></FaRegCheckCircle>
                <Spacer w={8}></Spacer>
                Weitere Informationen per Mail
              </div>
            </div>
          </label>
          {status !== "done" && (
            <button
              className="contactSubmit"
              onClick={(name || phone || mail) && handleSubmit}
            >
              {status === "start" ? (
                "Anfrage abschicken"
              ) : status === "loading" ? (
                <Loading type="spin" width={20} height={20} color="white" />
              ) : (
                <>
                  <FaCheck></FaCheck>
                </>
              )}
            </button>
          )}

          {status === "done" && (
            <div className="contactSuccess">
              Ihre Anfrage wurde erfolgreich versendet. Wir melden uns in Kürze
              bei Ihnen.
              <br></br>
              <br></br>
              <br></br>
              <Button to={"/"} filled>
                Zurück zur Website
              </Button>
            </div>
          )}
        </div>
      </div>
      <Spacer h={80}></Spacer>
    </Layout>
  );
}
