import React, { useEffect, useMemo, useRef, useState } from "react";
import "./IntersectionNotifier.scss";

export default function IntersectionNotifier({
  hasScrolledIntoView = () => {},
}) {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  useEffect(() => {
    if (isVisible) {
      console.log("scrolled into view");
      hasScrolledIntoView();
    }
  }, [isVisible, hasScrolledIntoView]);
  return <div className="IntersectionNotifier" ref={ref}></div>;
}

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(() => {
    console.log("remomemo");
    return new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );
  }, []);

  useEffect(() => {
    observer.observe(ref.current);
    console.log("reefect");
    return () => observer.disconnect();
  }, [observer, ref]);

  return isIntersecting;
}
