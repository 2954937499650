import React, { useState } from "react";
import Button from "../../atoms/Button/Button";
import Hero from "../../atoms/Hero/Hero";
import Layout from "../../atoms/Layout/Layout";
import PhoneBox from "../../atoms/PhoneBox/PhoneBox";
import PriceArea from "../../atoms/PriceArea/PriceArea";
import Spacer from "../../atoms/Spacer/Spacer";
import TrippleArea from "../../atoms/TrippleArea/TrippleArea";
import "./Home.scss";

export default function Home() {
  let [activeElement, setActiveElement] = useState(0);
  return (
    <Layout className="Home" activeElement={activeElement}>
      <Hero
        bg="hero"
        mobileBg={"mobileHero"}
        hasScrolledIntoView={() => setActiveElement(null)}
        anchor={"top"}
      >
        <div className="heroContent">
          <div className="heroContentInnter">
            <div className="heroLine">Die neue</div>
            <div className="heroLine orange bold capitalize">Dimension</div>
            <div className="heroLine">
              des <span className="bold">Exponats</span>
            </div>

            <Spacer h={24}></Spacer>

            <Button large filled to="/contact">
              Demo anfordern
            </Button>
          </div>
        </div>
        <div className="flex1"></div>

      </Hero>

      <PhoneBox
        anchor={"entdecken"}
        hasScrolledIntoView={() => setActiveElement(0)}
        head={"Exponat"}
        title={
          <>
            Erlebe <span className="orange">interaktive</span> Ausstellungen
          </>
        }
        text={
          "Begeistern Sie Besucher mit faszinierendem Wissen und erhöhen Sie die Interaktivität durch zusätzliche digitale Inhalte wie Audio, Bilder, Videos, Grafiken und mehr!"
        }
        phone={"1.png"}
      ></PhoneBox>
      <PhoneBox
        head={"Führung"}
        title={
          <>
            Starte <span className="orange">individuelle</span> Führungen
          </>
        }
        text={
          "Folgen Sie geführten Touren oder durchstöbern Sie das Sortiment - Sie enscheiden selbst!"
        }
        phone={"2.png"}
      ></PhoneBox>
      <PhoneBox
        head={"Sprache"}
        title={
          <>
            Nutze <span className="orange">deine</span> Muttersprache
          </>
        }
        text={
          "Wählen Sie aus einer Vielzahl an Sprachen und tauchen Sie noch tiefer in die Welt des Museums ein."
        }
        phone={"3.png"}
      ></PhoneBox>
      <PhoneBox
        head={"Schatzsuche"}
        title={
          <>
            Löse spannende <span className="orange">Rätsel</span>
          </>
        }
        text={
          "Gehen Sie mit unserer digitalen Schnitzeljagd auf Entdeckertour und lernen Sie dabei mehr über die vorhandenen Kunstwerke."
        }
        phone={"4.png"}
      ></PhoneBox>
      <PhoneBox
        head={"Karte"}
        title={
          <>
            Finde dein <span className="orange">liebstes</span> Exponat
          </>
        }
        text={
          "Mit unserer digitalen Museumskarte finden Sie Ihren Weg zu jedem Exponat auf einfache Art und Weise."
        }
        phone={"5.png"}
      ></PhoneBox>
      <TrippleArea
        hasScrolledIntoView={() => setActiveElement(1)}
      ></TrippleArea>

      <PriceArea hasScrolledIntoView={() => setActiveElement(2)}></PriceArea>
    </Layout>
  );
}
