import React from "react";
import Button from "../Button/Button";
import IntersectionNotifier from "../IntersectionNotifier/IntersectionNotifier";
import Spacer from "../Spacer/Spacer";
import "./PhoneBox.scss";

export default function PhoneBox({
  anchor,
  hasScrolledIntoView = () => {},
  head,
  title,
  text,
  phone,
}) {
  return (
    <>
      {anchor && (
        <a id={anchor} href="/#" className="invisibleAnchor">
          #
        </a>
      )}
      <div className="PhoneBox">
        <Spacer h={80}></Spacer>
        <div className="top">
          <div className="head">{head}</div>
          <Spacer h={24}></Spacer>
          <div className="title">{title}</div>
          <Spacer h={24}></Spacer>
          <div className="text">{text}</div>
          <Spacer h={24}></Spacer>
          <div className="desktopDemoButton">
            <Spacer h={24}></Spacer>
            <Button to="/contact" filled>Demo anfordern</Button>
          </div>
        </div>
        <div className="bottom">
          <img
            className="phoneImage"
            alt="iphone"
            src={"/phones/" + phone}
          ></img>
          <div className="mobileDemoButton">
            <Spacer h={24}></Spacer>
            <Button to="/contact" filled>Demo anfordern</Button>
          </div>
        </div>
        <div className="intersector">
          <IntersectionNotifier
            hasScrolledIntoView={hasScrolledIntoView}
          ></IntersectionNotifier>
        </div>
      </div>
    </>
  );
}
