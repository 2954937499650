import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import Button from "../Button/Button";
import IntersectionNotifier from "../IntersectionNotifier/IntersectionNotifier";
import Spacer from "../Spacer/Spacer";
import "./PriceArea.scss";

export default function PriceArea({ hasScrolledIntoView }) {
  return (
    <div className="PriceArea">
      <a id={"loslegen"} href="/#" className="invisibleAnchor">
        #
      </a>
      <Spacer h={80}></Spacer>
      <div className="title">
        <span className="orange">Gemeinsam</span> DURCHSTARTEN
      </div>
      <div className="sub">Für alles den richtigen Preis</div>
      <Spacer h={80}></Spacer>
      <IntersectionNotifier
        hasScrolledIntoView={hasScrolledIntoView}
      ></IntersectionNotifier>
      <div className="prices">
        <PriceBox
          title="Testversion"
          sub={<>&nbsp;</>}
          price={"0"}
          features={[
            "14-Tägige Testversion",
            "voller Funktionsumfang",
            "kostenlose Beratung",
          ]}
        ></PriceBox>
        <Spacer h={40}></Spacer>
        <PriceBox
          bar="KOSTENLOS STARTEN"
          title="Vollversion"
          price="1"
          sub="pro Besucher"
          features={[
            "Unbegrenzte Exponate",
            "Navigationskarte",
            "Audio-Guide & Touren",
            "Mehrsprachigkeit",
            "Schnitzeljagden",
            "QR-Code Interaktionen",
          ]}
        ></PriceBox>
      </div>
      <Spacer h={80}></Spacer>
    </div>
  );
}

function PriceBox({ bar, title, price, features, pricePre, priceAfter, sub }) {
  return (
    <div className="PriceBox">
      {bar ? (
        <div className="bar">{bar}</div>
      ) : (
        <div className="hiddenBar"></div>
      )}
      <Spacer h={24}></Spacer>
      <div className="title">{title}</div>
      <Spacer h={24}></Spacer>
      <div className="price">
        <div className="pricePre">{pricePre}</div>
        {price}
        <div className="currency">€</div>
        <div className="pricePre">{priceAfter}</div>
      </div>
      <div className="subText">{sub}</div>
      <Spacer h={40}></Spacer>
      <div className="featureTitle">Im Paket enthalten</div>
      <Spacer h={24}></Spacer>
      <div className="features">
        {features.map((feature) => {
          return (
            <div className="feature">
              <div className="icon">
                <FaRegCheckCircle></FaRegCheckCircle>
              </div>
              {feature}
            </div>
          );
        })}
      </div>
      <Spacer h={80}></Spacer>
      <div className="flex1"></div>
      <div className="button">
        <Button to="/contact" border>
          Jetzt loslegen
        </Button>
      </div>
      <Spacer h={24}></Spacer>
    </div>
  );
}
